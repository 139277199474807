<template>
    <modal ref="modalProducto" titulo="Producto" tamano="modal-xl" no-aceptar no-cancelar>
        <div class="row mx-0">
            <div class="col-4">
                <vueper-slides class="no-shadow text-muted2 h-vueper3" :arrows-outside="false" :visible-slides="1" :touchable="false" autoplay :arrows="true">
                    <vueper-slide
                    v-for="(slide, idx) in 6"
                    :key="idx"
                    >
                        <template v-slot:content>
                            <div class="row mx-0 justify-center">
                                <img :src="producto.foto" class="obj-cover border br-12 img-product" width="266" height="266" />
                            </div>
                        </template>
                    </vueper-slide>
                    <template #arrow-left>
                        <div class="circled-arrow d-middle-center text-general border rounded-circle" style="width:24px;height:24px;">
                            <i class="icon-angle-left f-12" />
                        </div>
                    </template>
                    <template #arrow-right>
                        <div class="circled-arrow d-middle-center text-general border rounded-circle" style="width:24px;height:24px;">
                            <i class="icon-angle-right f-12" />
                        </div>
                    </template>
                </vueper-slides>
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div class="col px-0">
                        <p class="text-general f-20 f-600">
                            {{ producto.producto }}
                        </p>
                        <p class="text-gris2 f-15">
                            {{ producto.presentacion }}
                        </p>
                        <div class="row mx-0 mt-2">
                            <div class="col-auto px-0 text-gris2 f-15">
                                <span class="f-600 mr-1">Barcode:</span>
                                {{ producto.barcode }}
                            </div>
                            <div class="col-auto px-0 text-gris2 f-15 ml-3">
                                <span class="f-600 mr-1">SKU:</span>
                                {{ producto.sku }}
                            </div>
                        </div>
                    </div>
                    <div class="col px-0">
                        <p class="text-general f-20 f-600 mb-2">
                            Proveedor
                        </p>
                        <p class="text-general f-12 pl-3 mt-2">
                            Proveedor favorito (Opcional)
                        </p>
                        <div class="row mx-0">
                            <div class="col-10 px-0">
                                <el-select v-model="idProveedor" placeholder="Seleccionar" size="small" class="w-100" @change="cambiarProveedor">
                                    <el-option
                                    v-for="item in proveedores"
                                    :key="item.id"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                            </div>
                        </div>
                        <div class="row mx-0 align-items-center mt-3">
                            <el-checkbox v-model="inventarioProveedor" class="check-green" @change="putInventarioProveedor($event)" />
                            <span class="f-14 ml-2 text-general"> Inventario del proveedor </span>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mt-5">
                    <p class="col-12 px-0 text-general f-600 f-20 mb-4">
                        Rotación
                    </p>
                    <div class="col-auto pl-0 text-general f-15">
                        <span class="f-600 mr-2">Rotación general:</span>
                        {{ producto.rotacion_general }}
                    </div>
                    <div class="col-auto text-general f-15 ml-4">
                        <span class="f-600 mr-2">Rotación cedis:</span>
                        {{ producto.rotacion_cedis }}
                    </div>
                </div>
                <div class="row mx-0 mt-5">
                    <p class="col-12 px-0 text-general f-600 f-20 mb-4">
                        Unidades
                    </p>
                    <div class="col-12 px-0">
                        <div class="row mx-0 text-general">
                            <div class="col-3 pl-0 f-15">
                                <span class="f-600">Última compra:</span>
                                <span class="ml-2">{{ producto.ultima_compra_cantidad }}</span>
                            </div>
                            <div class="col-3 pl-0 f-15">
                                <span class="f-600">Valor compra:</span>
                                <span class="ml-2">10 Feb. 2022</span>
                            </div>
                            <div class="col-3 pl-0 f-15">
                                <span class="f-600">Fecha de compra:</span>
                                <span class="ml-2">{{ producto.ultima_compra_fecha }}</span>
                            </div>
                        </div>
                        <hr class="my-3" />
                        <div class="row mx-0 text-general">
                            <div class="col-3 pl-0 f-15">
                                <span class="f-600">Unidades disponibles:</span>
                                <span class="ml-2">{{ producto.cantidad_existente }}</span>
                            </div>
                            <div class="col-3 pl-0 f-15">
                                <span class="f-600">Precio de venta:</span>
                                <span class="ml-2">$</span>
                            </div>
                            <div class="col-3 pl-0 f-15">
                                <i class="icon-cash-lock-open f-18" />
                                <span class="ml-3">Ventas sin stock: {{ producto.venta_sin_stock == 1 ? 'SI' : 'NO' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Servicio from "~/services/proveedores/compras";
export default {
    data(){
        return {
            producto: {},
            proveedores: [],
            idProveedor: '',
            inventarioProveedor: false,
            slides: [
                {text: 'La app responde por productos defectuosos, no tendrás nada de qué preocuparte'},
                {text: 'podrás conseguir un ingreso extra por cada producto que vendas'},
                {text: 'Convierte a tus vecinos en clientes. ¡todos ganan!'},
            ],
        }
    },
    methods: {
        async getProveedoresCedis(){
            try {
                const {data} = await Servicio.getProveedoresCedis(this.producto.id_cedis);
                this.proveedores = data.proveedores;
                this.idProveedor = this.producto.id_proveedor_favorito;
            } catch (error){
                this.error_catch(error)
            }
        },
        async putInventarioProveedor(){
            try {
                let inventario = this.inventarioProveedor == true ? 1 : 0;
                const form = {
                    idProducto: this.producto.id_producto,
                    inventarioProveedor: inventario
                };
                const {data} = await Servicio.putInventarioProveedor(this.producto.id_cedis, form);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.producto.inventario_proveedor = inventario;
            } catch (error){
                this.error_catch(error)
            }
        },
        async cambiarProveedor(){
            try {
                const form = {
                    idProducto: this.producto.id_producto,
                    idProveedor: this.idProveedor
                };
                const {data} = await Servicio.putProveedorFavorito(this.producto.id_cedis, form);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                if(data.exito == true){
                    this.producto.id_proveedor_favorito = data.infoProveedores.id;
                    this.producto.proveedor_favorito = data.infoProveedores.nombre;
                    this.$emit('actualizar', this.producto.id_producto);
                    this.$refs.modalProducto.toggle();
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        toggle(producto){
            this.producto = producto;
            this.inventarioProveedor = producto.inventario_proveedor == 1 ? true : false;
            this.getProveedoresCedis();
            this.$refs.modalProducto.toggle();
        }
    }
}
</script>
